import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { minD, maxD } from "../../assets/styles/helpers/devices"

import { SecondaryTitle } from "../global/common/text"
import { GrayContainer, LineWrapper } from "../global/common/containers"
import {
  DotsContainer,
  FadedGreenBigBox,
  GreenBoxContainer,
} from "../global/common/ornaments"

const AboutWrapper = styled(LineWrapper)`
  padding-top: calc(var(--section-spacing) * 1.2);
  padding-bottom: calc(var(--section-spacing) * 1.2);

  @media ${maxD.tabletL} {
    flex-wrap: wrap;
  }
`

const AboutImageContainer = styled(GreenBoxContainer)`
  flex-shrink: 0;

  width: min(500px, 30vw);
  height: min(655px, 37vw);
  margin: 0 calc(var(--section-spacing) * 0.6);

  &::after {
    left: calc(var(--section-spacing) * -0.2);
    bottom: calc(var(--section-spacing) * 0.4);
  }

  @media ${maxD.tabletL} {
    width: calc(100% + var(--section-spacing) * -0.6);
    margin: calc(var(--section-spacing) * 1) calc(var(--section-spacing) * 0.6)
      calc(var(--section-spacing) * 0.7);
  }

  @media ${maxD.mobileL} {
    height: min(655px, 80vw);
  }
`

const AboutImage = styled(FadedGreenBigBox)`
  width: 100%;
  height: 100%;

  position: relative;

  &::before {
    left: calc(var(--section-spacing) * -0.7);
    top: calc(var(--section-spacing) * -0.8);

    @media ${maxD.mobileL} {
      left: calc(var(--section-spacing) * -0.6);
    }
  }
`

const AboutContent = styled(DotsContainer)`
  @media ${minD.tabletL} {
    padding-right: calc(var(--section-spacing) * 0.25);
  }

  &::after {
    width: 900px;
    height: 700px;

    left: -200px;
    top: -50px;
  }
`

const AboutTheProject = ({ data }) => {
  const image = {
    url: data.csAboutImage?.localFile.childImageSharp.gatsbyImageData,
    alt: data.csAboutImage?.altText,
  }

  const title = data.csAboutTitle
  const description = data.csAboutDescription

  return (
    <GrayContainer>
      <AboutWrapper flex alignItems="c">
        {image.url && (
          <AboutImageContainer>
            <AboutImage
              image={image.url}
              alt={image.alt}
              as={GatsbyImage}
              style={{ overflow: "visible" }}
            />
          </AboutImageContainer>
        )}
        <AboutContent>
          <SecondaryTitle
            dangerouslySetInnerHTML={{ __html: title }}
            style={{ "--spacing-bottom": "40px" }}
          />
          <div dangerouslySetInnerHTML={{ __html: description }}></div>
        </AboutContent>
      </AboutWrapper>
    </GrayContainer>
  )
}

export default AboutTheProject
