import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"

import { UnstyledList } from "./common/essentials"
import { SecondaryTitle } from "./common/text"

import toSlug from "../../helpers/toSlug"

const PointsContainer = styled(UnstyledList)`
  width: 100%;
  border: 1px solid var(--border-green);
`

const Point = styled.li`
  padding: 2.5rem 2rem;

  background-color: var(--point-background-color, transparent);

  &:not(:last-of-type) {
    border-bottom: 1px solid var(--border-green);
  }
`

const PointLogos = styled(GatsbyImage)`
  max-width: 100%;
  max-height: 28px;

  margin-top: 1.8rem;
`

const Points = ({ style, points }) => (
  <PointsContainer style={style}>
    {points &&
      points.map((point, i) => {
        const title = point?.csImportantPointsSubTitle
        const description = point?.csImportantPointsDescription
        const slug = toSlug(title)

        const imageBase = point?.csImportantPointsImage
        const isSvg = imageBase?.localFile.extension === "svg"

        const image = {
          url: isSvg
            ? imageBase?.localFile.publicURL
            : imageBase?.localFile.childImageSharp.gatsbyImageData,
          alt: imageBase?.altText,
        }

        return (
          <Point key={`${i}-${slug}`}>
            <SecondaryTitle
              dangerouslySetInnerHTML={{
                __html: title,
              }}
              style={{ "--spacing-bottom": "18px" }}
            />
            <div dangerouslySetInnerHTML={{ __html: description }}></div>
            {image.url &&
              (isSvg ? (
                <PointLogos src={image.url} alt={image.alt} as="img" />
              ) : (
                <PointLogos image={image.url} alt={image.alt} />
              ))}
          </Point>
        )
      })}
  </PointsContainer>
)

export default Points
